<template>
  <div></div>
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useRouter, useRoute } from 'vue-router'
import axios from '@/axios'
export default {
  data() {
    return {
      router: useRouter(),
      route: useRoute(),
    }
  },
  mounted() {
    axios
      .post('/registration/confirm_email/' + this.route.query['confirm-token'])
      .then((res) => {
        Swal.fire({
          title: 'Your Email is verified!',
          text: 'Once your registration is approved, you will be notified via email',
          icon: 'success',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.value) {
            this.router.push('/auth/login')
          }
        })
      })
      .catch((err) => {
        const error = err.response.data.message
        let messages
        if (Array.isArray(error)) messages = [...err.response.data.message]
        else messages = err.response.data.message
        Swal.fire({
          title: 'Error!',
          text: messages,
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.value) {
            this.router.push('/auth/login')
          }
        })
      })
  },
}
</script>

<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
